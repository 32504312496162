import React from 'react';
import pessoa1 from '../../../img/Pessoa1.png';
import pessoa2 from '../../../img/Pessoa2.png'
import pessoa3 from '../../../img/Pessoa3.png'

const Clientes = () => {
  return (
    <div className="text-center p-6">
      <div className="flex justify-center mb-4">
        <div className="w-12 h-12 rounded-full border-2 border-white overflow-hidden">
          <img src={pessoa1} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="w-12 h-12 rounded-full border-2 border-white overflow-hidden">
          <img src={pessoa2} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="w-12 h-12 rounded-full border-2 border-white overflow-hidden">
          <img src={pessoa3} alt="" className="w-full h-full object-cover" />
        </div>
      </div>
      <h2 className="text-xl font-bold">Cadastre seus Pacientes</h2>
<p className="text-muted-foreground mt-2">Tenha o controle de todos os seus pacientes</p>

<a
  href="https://wa.me/5524992178767"
  target="_blank"
  rel="noopener noreferrer"
  className="bg-[#17A34A] text-white hover:bg-[#17A34A]/80 mt-4 px-4 py-2 rounded-full inline-block text-center no-underline"
>
  Fale com a gente
</a>
    </div>
  );
};

export default Clientes;

