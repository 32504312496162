
import React, { useEffect, useState, useCallback } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../InfoUsuario/SobreMim.css';
import { IMaskInput } from 'react-imask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';


const overlayStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.9)', // Fundo escurecido
  zIndex: '999', // Garante que fique atrás do cardStyle
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const cardStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '300px',
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  padding: '20px',
  textAlign: 'center',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'none',
  border: 'none',
  color: '#aaa',
  cursor: 'pointer',
};




export default function SobreMim() {
  const [userAltura, setUserAltura] = useState(0);
  const [userPeso, setUserPeso] = useState(0);
  const [userAge, setUserAge] = useState('');
  const [genero, setGenero] = useState('');
  const [aguaRecomendada, setAguaRecomendada] = useState(0);
  const [color, setColor] = useState('text-black')
  const [msgSave, setMsgSave] = useState('');
  const [alteracao, setAlteracao] = useState(false);
  const [labelAlterar, setLabelAlterar] = useState('Atualizar Dados');
  const [bmi, setBMI] = useState(0);
  const [status, setStatus] = useState('');

  const { retornaDados, salvarDados } = UserAuth();
  const navigate = useNavigate();
  const handlerDietas = () =>{
    navigate('/dieta')
  }

  const calcularAguaNecessaria = useCallback(() => {
    const agua = (userPeso ? 0.035 * userPeso : 0).toFixed(2);
    setAguaRecomendada(agua);
  }, [userPeso]);


  const getPointerPosition = () => {
    const minBMI = 15;
    const maxBMI = 40;
    const bmiValue = parseFloat(bmi);
    if (bmiValue < minBMI) {
      return 0;
    } else if (bmiValue > maxBMI) {
      return 100;
    } else {
      return ((bmiValue - minBMI) / (maxBMI - minBMI)) * 100;
    }
  };

  useEffect(() => {
    retornaDados()
      .then((array) => {
        if (array !== null) {
          setUserPeso(array[0]);
          setUserAltura(array[1]);
          const bmiValue = (array[0] / (array[1] * array[1])).toFixed(1);
          setBMI(bmiValue);
      
          if (bmiValue < 18.5) {
            setStatus('Abaixo do peso');
            setColor('text-yellow-600')
          } else if (bmiValue >= 18.5 && bmiValue < 24.9) {
            setStatus('Peso Saúdavel');
            setColor('text-green-600')
          } else if (bmiValue >= 25 && bmiValue < 29.9) {
            setStatus('Acima do peso');
            setColor('text-orange-600')
          } else {
            setStatus('Obesidade');
            setColor('text-red-600')
          }
        } else {
          console.log('Sem dados ainda');
        }
      })
      .catch((error) => {
        console.error('Erro ao obter os dados:', error);
      });
  }, [retornaDados]);


  useEffect(() => {
    calcularAguaNecessaria();
  }, [userPeso, calcularAguaNecessaria]);

  const handleAlterar = () => {
    setAlteracao(!alteracao);
    setLabelAlterar("Alterar Dados");
    setMsgSave("");
  };

  const handleFechar = () => {
    setAlteracao(!alteracao);
  }

  const handleSalvar = async () => {
    const imc = userPeso / (userAltura * userAltura);
    try {   
      await salvarDados(userPeso, userAltura, parseInt(imc));
    } catch {
    } finally {
      setMsgSave("Dados Salvos com Sucesso!");
      navigate(window.location.pathname);
    }
  };

  return (
<div className='sobre-mim-container  lg:w-[50%] w-[80%] mx-auto mt-10'>
  <h2 className='text-center text-gray-600'>Informações </h2>
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    border: '1px solid #eee',
    borderRadius: '15px',
    padding: '20px',
    textAlign: 'center',
    transition: 'all 0.3s ease-in-out',
  }}>
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
      <div className='card bg-white rounded-lg p-4 hover:scale-105 transition-transform duration-300' style={{ width: '45%' }}>
        <h3 className='text-md text-center text-gray-800 font-semibold'>Peso </h3>
        <h2 className='text-sm text-center text-gray-900 font-bold'>{userPeso}</h2>
      </div>

      <div className='card bg-white rounded-lg p-4 hover:scale-105 transition-transform duration-300' style={{ width: '45%' }}>
        <h3 className='text-md text-center text-gray-800 font-semibold'>Altura </h3>
        <h2 className='text-sm text-center text-gray-900 font-bold'>{userAltura}</h2>
      </div>
    </div>
    
    <div className='card bg-white rounded-lg p-3 hover:scale-105 transition-transform duration-300' style={{ width: '100%', marginTop: '20px' }}>
      <h3 className='text-md  text-center text-gray-800 font-semibold'>Água/Dia 💦</h3>
      <h2 className='text-sm text-center text-gray-900 font-bold'>{aguaRecomendada} L/dia</h2>
    </div>
  </div>


  
<br></br>
    <div className='mb-[10px]'>
      {bmi && (
        <div className="bg-white rounded-lg p-8 shadow-lg  max-w-4xl mx-auto" style={{ border: '1px solid #D9D8D8' }}>
          <div className="mb-4">
            <div className="flex items-center mb-4">
              <img
                alt="gear-icon"
                src="https://openui.fly.dev/openui/24x24.svg?text=⚙️"
                className="mr-2"
              />
              <h2 className="text-2xl font-semibold">IMC</h2>
            </div>
            <p className={`${color} text-lg`}>{status}</p>
          </div>
          <div className="flex items-center justify-between mb-4">
            <div className="relative w-full">
              <div className="flex justify-between text-sm text-gray-600 mb-1">
                <span>15</span>
                <span>18</span>
                <span>25</span>
                <span>30</span>
                <span>35</span>
                <span>40</span>
              </div>
              <div className="h-3 bg-gray-200 rounded-full flex">
                <div className="h-full bg-yellow-500 rounded-l-full" style={{ width: '22%' }}></div>
                <div className="h-full bg-green-500" style={{ width: '20%' }}></div>
                <div className="h-full bg-orange-500" style={{ width: '20%' }}></div>
                <div className="h-full bg-red-500 rounded-r-full" style={{ width: '40%' }}></div>
              </div>
              <div
                className="absolute top-[-10px] transform -translate-x-1/2"
                style={{ left: `${getPointerPosition()}%` }}
              >
                <div className="w-0 h-0 border-l-4 border-r-4 border-b-4 border-b-orange-500 border-l-transparent border-r-transparent"></div>
              </div>
            </div>
          </div>
          <div className="text-3xl font-bold text-center">{bmi}</div>
        </div>
      )}
        
      </div>

      {alteracao && (
        <div style={overlayStyle}>
          <div style={cardStyle}>
          <button onClick={handleFechar} style={closeButtonStyle}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
        <div >
          <div className='form-field'>
            <label htmlFor="weight">Peso (kg)</label>
            <input
              type="number"
              id="weight"
              maxLength={4}
              value={userPeso}
              onChange={(e) => setUserPeso(e.target.value)}
              placeholder="Digite seu Peso"
            />
          </div>
          <div className='form-field'>
            <label htmlFor="height">Altura (m)</label>
            <IMaskInput
              mask="0.00"
              type="number"
              id="height"
              maxLength={4}
              value={userAltura}
              onChange={(e) => setUserAltura(e.target.value)}
              placeholder="Digite sua Altura: Ex: 1.69"
            />
          </div>
          <div className='form-field'>
            <label htmlFor="age">Idade</label>
            <input
              type="number"
              id="age"
              value={userAge}
              onChange={(e) => setUserAge(e.target.value)}
              placeholder="Digite sua Idade"
            />
          </div>
          <div className='form-field'>
            <label htmlFor="Genero">Gênero</label>
            <select
              id="genero"
              value={genero}
              onChange={(e) => setGenero(e.target.value)}
            >
              <option value="">Informe seu gênero</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
            <button className='button mt-3' onClick={handleSalvar}>SALVAR</button>
            {msgSave && (<div className='success-message'>{msgSave}</div>)}
          </div>
        </div>
        </div>
        </div>
      )}
   
    <div className="flex justify-center space-x-4 p-4 mt-4 rounded-xl shadow bg-white">
      <button className="button" onClick={handleAlterar}>{labelAlterar}</button>
      <button className="button-dietas" onClick={handlerDietas}>Minhas Dietas</button>
    </div>
    <br></br>

    {msgSave && <div className='success-message'>{msgSave}</div>}
    
  </div>
);
}
