import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '../NewMetodo/StylesDiet.css';
import icone from '../../img/MascoteNutri.png';
import ProposalModal from '../NewMetodo/ProposalModal';
import SubscriptionComponent from "../MercadoPago/SubscriptionComponent/Subscription.component";
import { toast } from "react-toastify";
import { handlePaymentSuccess } from '../../context/ApiRequest'; // 
import DonwloadPDFDieta from "./DownloadPDF";
import { TailSpin } from "react-loader-spinner";

export default function MontarDietaDN() {
  const resultTextareaRef = useRef(null);
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [recomend, setRecomend] = useState(false)
  const [aguaRecomendada, setAguaRecomendada] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [imc, setImc] = useState('')
  const [answer, setAnswer] = useState('')
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [formData, setFormData] = useState({
    peso: '',
    altura: '',
    idade: '',
    genero: '',
    objetivo: '',
    alimentos: []
  });

  const toggleModal = (isOpen, goToPayment = false) => {
    setShowModal(isOpen);
    if (!isOpen && goToPayment) {
      setIsVisiblePayment(true);
      setStep(3);
    } else if (!isOpen) {
      setIsVisiblePayment(false);
    }
  };

  const myDivRef = useRef(null); 
  const calcularAguaNecessaria = () => {
    const agua = formData.peso ? 0.03 * formData.peso : 0;
    setAguaRecomendada(agua);
  };
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const nextStep = () => {
    if (step === 2) {
      setShowModal(true);
    } else if (step <= 3) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
      if (step === 3) {
        setIsVisiblePayment(true);
      }
    }
  };

  
    const handlePaymentSuccessLocal = async () => {
      setStep(4)
      await handlePaymentSuccess(formData.altura, formData.peso, formData.objetivo,formData.genero, formData.alimentos, setIsLoading, setLoadingProgress, setIsVisiblePayment, setImc, setMensagem, setRecomend, calcularAguaNecessaria, setAnswer);
      console.log(answer, aguaRecomendada, imc,mensagem, isLoading, loadingProgress)
  };
  
  document.querySelectorAll('.label-mont input[type="checkbox"]').forEach(input => {
    input.addEventListener('change', function() {
        if (this.checked) {
            this.parentNode.style.backgroundColor = '#17A34A';
            this.parentNode.style.color = 'white';
        } else {
            this.parentNode.style.backgroundColor = '';
            this.parentNode.style.color = '';
        }
    });
});

  const handlePaymentWaiting = () => {
    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa página!');
  };

  const amount = 0.10; // Valor para teste

  return (
    <div className="form-container">
      <h1 className="title">Nutri Inteligente 🥦</h1>
      <div className="progress-container">
        <div className={`progress ${step === 1 ? 'step1' : step === 2 ? 'step2' : step === 3 ?'step3' : 'step4'}`}></div>
      </div>

      {step === 1 && (
        <div>
          <div className="image-container">
            <img src={icone} alt="Nutrition Mascot" style={{ height: '270px' }} />
          </div>
          <input
            className="input-mont"
            type="number"
            placeholder="Peso"
            name="peso"
            value={formData.peso}
            onChange={handleInputChange}
          />
          <input
            className="input-mont"
            type="number"
            placeholder="Altura"
            name="altura"
            value={formData.altura}
            onChange={handleInputChange}
          />
          <input
            className="input-mont"
            type="number"
            name="idade"
            placeholder="Idade"
            value={formData.idade}
            onChange={handleInputChange}
          />
          <select name="genero" className="input-mont"
            value={formData.genero} onChange={handleInputChange}>
            <option value="">Gênero</option>
            <option value="Masculino">Masculino</option>
            <option value="Feminino">Feminino</option>
          </select>
        </div>
      )}
      {step === 2 && (
        <div>
         <label className="label-mont">Objetivo:</label>
  <select
    className="input-mont"
    name="objetivo"
    value={formData.objetivo}
    onChange={handleInputChange}
  >
    <option value="">Selecione seu objetivo</option>
    <option value="Emagrecimento">Emagrecimento</option>
    <option value="Ganho de massa muscular">Ganho de massa muscular</option>
    <option value="Definição muscular">Definição muscular</option>
    <option value="Definição + Ganho de massa muscular">Definição + Ganho de massa muscular</option>
    <option value="Jejum intermitente">Jejum intermitente</option>
    <option value="Emagrecimento + Ganho de massa muscular">Emagrecimento + Ganho de massa muscular</option>
  </select>
          <h1 className="food-header">
            Quais alimentos deseja incluir?
          </h1>
          <div className="food-options">
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Arroz" onChange={handleInputChange} /> Arroz 🍚</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Aveia" onChange={handleInputChange} /> Aveia 🌾</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Batata Inglesa" onChange={handleInputChange} /> Batata Inglesa 🥔</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Batata Doce" onChange={handleInputChange} /> Batata Doce 🥔</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Biscoito" onChange={handleInputChange} /> Biscoito 🍪</label>            <label className="label-mont"><input type="checkbox" value="Biscoito" onChange={handleInputChange} /> Iogurte 🥛</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Barra de Cereais" onChange={handleInputChange} /> Barra de Cereais 🍬</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Carne Vermelha" onChange={handleInputChange} /> Carne 🥩</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Carne de Porco" onChange={handleInputChange} /> Carne-Porco 🐖</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Cereal" onChange={handleInputChange} /> Cereal 🥣</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Cuzcuz" onChange={handleInputChange} /> Cuzcuz 🍚</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Café" onChange={handleInputChange} /> Café ☕</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Cereal" onChange={handleInputChange} /> Cereal 🥛</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Crepioca" onChange={handleInputChange} /> Crepioca 🥞</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Feijão" onChange={handleInputChange} /> Feijão 🍲</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Frango" onChange={handleInputChange} /> Frango 🍗</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Frutas" onChange={handleInputChange} /> Frutas 🍎</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Iogurte" onChange={handleInputChange} /> Iogurte 🥛</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Inhame" onChange={handleInputChange} /> Inhame 🍠</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Legumes" onChange={handleInputChange} /> Legumes 🥦</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Leite" onChange={handleInputChange} /> Leite 🥛</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Macarrão" onChange={handleInputChange} /> Macarrão 🍝</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Ovo" onChange={handleInputChange} /> Ovo 🥚</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Mandioca" onChange={handleInputChange} /> Mandioca 🍠</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Pão de Queijo" onChange={handleInputChange} /> Pão de Queijo 🧀</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Pão Francês + complementos" onChange={handleInputChange} /> Pão Francês 🥖</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Pão de forma + complementos" onChange={handleInputChange} /> Pão de Forma 🍞</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Pasta de Amendoin" onChange={handleInputChange} /> Pasta de Amendoin 🌰</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Peixe" onChange={handleInputChange} /> Peixe 🐟</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Queijo" onChange={handleInputChange} /> Queijo 🧀</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Rap10" onChange={handleInputChange} /> Rap 10 🌯</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Sanduiche de Frango" onChange={handleInputChange} /> Sanduiche de Frango 🥛🥪</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Sanduiche de Peru" onChange={handleInputChange} /> Sanduiche de Peru 🥪</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Suco" onChange={handleInputChange} /> Suco 🥤</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Salada" onChange={handleInputChange} /> Salada 🥗</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Salada de Frutas" onChange={handleInputChange} /> Salada de Frutas 🥗</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Tapioca" onChange={handleInputChange} /> Tapioca 🥙</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Vitamina" onChange={handleInputChange} /> Vitamina 🥤</label>
            <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Whey" onChange={handleInputChange} /> Whey 🥛</label>


          </div>
        </div>
      )}
      {step === 3 && isVisiblePayment && (
        <div ref={myDivRef} className="payment-method-selector">
          <SubscriptionComponent
            amount={amount}
            onPaymentSuccess={handlePaymentSuccessLocal}
            onPaymentWaiting={handlePaymentWaiting}
            addOrderBump={true}
            linkexterno={false}
          />
        </div>
      )}
      <div className="button-group px-4">  
      {recomend && step === 4 && (<div className="chat-response">
        <label htmlFor="response" className=" font-bold text-xl text-center">Dieta Personalizada</label>
        <textarea className="w-full p-4"
          id="response"
          ref={resultTextareaRef}
          name="response"
          rows="100"
          value={answer}
          readOnly
        >
        </textarea>
      </div>)}
        {step > 1 && step !== 4 &&(
          <button className="button-mont" onClick={prevStep}>
            <FontAwesomeIcon icon={faArrowLeft} />
            Voltar
          </button>
        )}
        {step <= 2 ? (<button className="button-mont" onClick={step === 3 ? handlePaymentSuccessLocal : nextStep }>
          {step <= 2 ? 'Próximo' : ''}
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
        ) : (answer && (<DonwloadPDFDieta 
          dieta={answer}
          altura={formData.altura}
          peso={formData.peso} 
          imc={imc}
          mensagem={mensagem}
          aguaRecomendada={aguaRecomendada} >
            
          </DonwloadPDFDieta>)
      )  } 
      {isLoading && (
        <div className="overlay">
          <div className="Spinner">

            <div className="ComidaEmoji">🥦</div>

            <TailSpin
              color="#00dc25"
              height={80}
              width={80}
            />
            <p>Carregando ⏳...{loadingProgress}%</p>
            <p className="AvisoNaoSaia">Por favor, não saia da página enquanto geramos sua dieta 🥗.</p>
          </div>
        </div>
      )}

      </div>
      <ProposalModal
        isOpen={showModal}
        onRequestClose={() => toggleModal(false)}
        onConfirm={() => toggleModal(false, true)}
      />
    </div>
  );
}
