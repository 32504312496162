import React from 'react';
import Range from '../Range/RangePlanos';

export default function Principal() {
  return (
    <>
      <Range />
    </>
  );
}
