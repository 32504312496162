import jsPDF from "jspdf";
import header from '../../img/Header.png'

const DonwloadPDFDieta = ({dieta, altura, peso, imc, mensagem, aguaRecomendada}) => {
const handleDownloadPDF = () => {
    if (!dieta) {
      return;
    }
    const pdf = new jsPDF();
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height - 1 * 8;
    const fontSize = 12;
    const dado = `Altura: ${altura}\nPeso: ${peso}\nIMC: ${imc}\n${mensagem}\nQuantidade de Água Mínima Recomendada: ${aguaRecomendada.toFixed(2)}L\n`

    const splitText = pdf.splitTextToSize(`\nDieta Totalmente Personalizada e Exclusiva\nAconselhável Trocar a Dieta Depois de 20 Dias\n${dado}\n${dieta}`, pageWidth - margin * 2);

    const headerHeight = 49;
    pdf.addImage(header, 'PNG', 0, 0, pageWidth, headerHeight); // Ajusta a largura da imagem para corresponder à largura total da página

    let yPosition = margin + headerHeight - 8; 

    for (let i = 0; i < splitText.length; i++) {
      if (yPosition + 5 > pageHeight) {
        pdf.addPage();
        yPosition = margin;
      }

      pdf.setFontSize(fontSize);

      if (splitText[i].includes('Dieta Totalmente Personalizada e Exclusiva')) {
        pdf.setFontSize(16)
      } else {
        pdf.setTextColor(0);
      }

      if (splitText[i].includes('Café da Manhã') ||
        splitText[i].includes('Lanche da Manhã') ||
        splitText[i].includes('Café da manhã') ||
        splitText[i].includes('Lanche da manhã') ||
        splitText[i].includes('Almoço') ||
        splitText[i].includes('Lanche da Tarde') ||
        splitText[i].includes('Lanche da tarde') ||
        splitText[i].includes('Dieta Totalmente Personalizada e Exclusiva') ||
        splitText[i].includes('Janta')) {
        pdf.setTextColor(50, 205, 50); // Cor azul 
      } else {
        pdf.setTextColor(0); // Volta à cor padrão do texto (preto)
      }

      pdf.text(margin, yPosition, splitText[i]);

      yPosition += fontSize - 2;
    }


    // Rodapé moderno
    pdf.setFillColor('#1C9E22'); // Cor de fundo verde
    pdf.rect(0, pageHeight - 10, pageWidth, 30, 'F');
    pdf.setFontSize(12); // Ajusta o tamanho da fonte
    pdf.setTextColor('#ffffff'); // Texto branco

    // Centraliza o texto no rodapé
    let footerText = 'Caso tenha alguma dúvida, entre em contato.';
    let textWidth = pdf.getTextWidth(footerText);
    pdf.text((pageWidth - textWidth) / 2, pageHeight - 5, footerText);

    const fileName = 'Nutri Inteligente.pdf';
    pdf.save(fileName);
};

return(
    <div><button  className='bg-red-500 hover:bg-red-700 text-white font-bold  w-[110px] h-[60px] rounded mx-auto' onClick={handleDownloadPDF}>Baixar</button></div>
)
}

export default DonwloadPDFDieta;