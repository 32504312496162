import React, { useEffect, useState, useCallback, useRef } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import '../Error/ErrorCard1.css'
import ErrorCard from '../Error/ErrorCard';
import ErrorCard3 from '../Error/ErrorCard3';
import ErrorCard2 from '../Error/ErrorCard2';
import header from '../../img/Header.png'
import HamburguerMenu from './MenuDieta/MenuDieta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import coffe from '../../img/Cafemanha.png'
import treino1 from '../../img/treino.png'
import almoco1 from '../../img/saladaverde.png'
import lanche from '../../img/Pao.png'
import lancheM from '../../img/MaçaVermelha.png'
import horario from '../../img/horario.png'
import janta1 from '../../img/Janta.png'
import MenuI from '../../img/saladaverde.png'
import Banner from './Banner';
import Banner2 from './Banner2';
export default function LocalDieta() {

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [successMessageVisible, setSuccessMessageVisible] = useState(false)
  const [userDieta, setUserDieta] = useState(null);
  const [info, setInfo] = useState(false)
     // eslint-disable-next-line
  const [userTickets, setUserTickets] = useState(null);
  const [userAltura, setUserAltura] = useState(null);
  const [userPeso, setUserPeso] = useState(null);
  const [userImc, setUserImc] = useState('');
  const [aguaRecomendada, setAguaRecomendada] = useState(0);
  const [possuiTicket, setPossuiTicket] = useState(false);
  const [showError3, setShowError3] = useState(true);
  const [showErro2 , setShowError2] = useState(true)
  const [showError, setShowError] = useState(true);
  const [visibleDieta, setVisibleDieta] = useState(true)
  const [userTicketsUsados,setUserTicketsUsados] = useState(null)
  const [mensagem, setMensagem] = useState('');
  // const [atendimento, setAtendimento] = useState(false)
  const [atendimentoPersonalizado, setAtendimentoPersonalizado] = useState(false)
  const { retornaDieta, retornaDados, retornaTicket, retornaTicketUsado, adicionarDieta,retirarEdicaoDieta, verificarAtendimentoPersonalizado } = UserAuth();
  const [edit, setEdit] = useState(false)
  const resultTextareaRef = useRef(null);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const [visibleIndices, setVisibleIndices] = useState([]);

  const handleToggleElement = (index) => {
    setVisibleIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        // Se o índice já estiver visível, removê-lo
        return prevIndices.filter((i) => i !== index);
      } else {
        // Se o índice não estiver visível, adicioná-lo
        return [...prevIndices, index];
      }
    });
  };

  // const handleAcompanhamento = () => {
  //   navigate('/atendimento')
  // };

  const handleAcompanhamentoPersonalizado = () => {
    navigate('/atendimentopersonalizado')
  }

  const calcularAguaNecessaria = useCallback(() => {
    const agua = userPeso ? 0.035 * userPeso : 0;
    setAguaRecomendada(agua);
  }, [userPeso]);

  useEffect(() => {
    calcularAguaNecessaria();
  }, [userPeso, calcularAguaNecessaria]);

  const handleSave = () => {
    adicionarDieta(resultTextareaRef.current.value);
    return;
  };


  const copyAnswer = () => {
    if (userDieta) {
      navigator.clipboard.writeText(userDieta)
        .then(() => {
          setSuccessMessageVisible(true);

          // Ocultar o mini card após 2 segundos (opcional)
          setTimeout(() => {
            setSuccessMessageVisible(false);
          }, 3000);
        })
        .catch(err => {
          console.error('Erro ao copiar para a área de transferência:', err);
        });
    }
  };

  const closeError = () => {
    setShowError(false);
  }


  const closeError3 = () => {
    setShowError3(false);
    Voltar()
  }

  const closeError2 = () => {
    setShowError2(false);

  }

  const Voltar = () => {
    navigate('/home');
  }


  useEffect(() => {
    // Simula um tempo de carregamento de 3 segundos
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Limpa o timer quando o componente é desmontado
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {

    retornaDados()
      .then((array) => {
        if (array !== null) {

          setUserPeso(array[0]);
          setUserAltura(array[1]);
          const imcCalculado = array[0] / (array[1] * array[1]);
          if(array[3]){
            setInfo(true)
          }else{
            setInfo(false)
          }
          setUserImc(imcCalculado.toFixed(1));
          if (imcCalculado < 18.5) {
            setMensagem("Você está abaixo do peso");
          } else if (imcCalculado >= 18.5 && imcCalculado <= 24.9) {
            setMensagem("Você está com peso normal");
          } else if (imcCalculado >= 25 && imcCalculado <= 29.9) {
            setMensagem("Você está com sobrepeso");
          } else if (imcCalculado >= 30) {
            setMensagem("Você está com obesidade");
          } else {
            setMensagem("Por favor, atualize seus dados de peso e altura.");

          }

        } else {
          console.log('Sem dados ainda');

        }
      })
      .catch((error) => {
        console.error('Erro ao obter os dados:', error);
      });
  }, [retornaDados]);



  useEffect(() => {
    retornaDieta()
      .then(async (dieta) => {
        if (dieta !== null) {
           setUserDieta(dieta);
        } else {
          setUserDieta('');
        }

      })
      .catch((error) => {
        console.error('Erro ao obter os tickets:', error);
        setUserDieta('Erro ao obter os tickets');
      });

    retornaTicket()
      .then((tickets) => {
        if (tickets !== null) {
          setUserTickets(tickets);
          if (tickets === "DietaNormal" || tickets === "DietaTreino") {
            setPossuiTicket(true)
          }

        } else {
          setUserTickets("Nenhum ticket disponível");
        }

      })
      .catch((error) => {
        setUserTickets('');

      });

    retornaTicketUsado()
      .then((tickets) => {
        if (tickets !== null) {
          setUserTicketsUsados(tickets);

        } else {
          console.log("Nenhum ticket disponível");
        }

      })
      .catch((error) => {
        console.log("Erro ao obter os tickets");
      });



  }, [retornaDieta, retornaTicket, possuiTicket,retornaTicketUsado, userTicketsUsados, userDieta]);


  // useEffect(()=> {
  //   verificarAtendimento().then((valor) => setAtendimento(valor))
  // }, [verificarAtendimento])
  useEffect(()=> {
    verificarAtendimentoPersonalizado().then((valor) => {
      setAtendimentoPersonalizado(valor)
    })

  }, [verificarAtendimentoPersonalizado, atendimentoPersonalizado])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const handleDownloadPDF = () => {
    if (!userDieta) {
      return;
    }

    const sanitizeText = (text) => {
      return text.replace(/[#@!$%^&*_+=[\]{};'"\\|,<>/?]+/g, ''); // Remove apenas caracteres especiais, sem afetar números e pontos decimais
    };

    const pdf = new jsPDF();
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height - 1 * 8;
    const fontSize = 12;

    // Sanitizar o conteúdo
    const dado = `Altura: ${userAltura}\nPeso: ${userPeso}\nIMC: ${userImc}\n${mensagem}\nQuantidade de Água Mínima Recomendada: ${aguaRecomendada.toFixed(2)}L\n`;

    const splitText = pdf.splitTextToSize(sanitizeText(`\nDieta Totalmente Personalizada e Exclusiva\nAconselhável Trocar a Dieta Depois de 20 Dias\n${dado}\n${userDieta}`), pageWidth - margin * 2);

    const headerHeight = 49;
    pdf.addImage(header, 'PNG', 0, 0, pageWidth, headerHeight); // Ajusta a largura da imagem para corresponder à largura total da página

    let yPosition = margin + headerHeight - 8;

    for (let i = 0; i < splitText.length; i++) {
      if (yPosition + 5 > pageHeight) {
        pdf.addPage();
        yPosition = margin;
      }

      pdf.setFontSize(fontSize);

      if (splitText[i].includes('Dieta Totalmente Personalizada e Exclusiva')) {
        pdf.setFontSize(16);
      } else {
        pdf.setTextColor(0);
      }

      if (splitText[i].includes('Café da Manhã') ||
          splitText[i].includes('Lanche da Manhã') ||
          splitText[i].includes('Café da manhã') ||
          splitText[i].includes('Lanche da manhã') ||
          splitText[i].includes('Almoço') ||
          splitText[i].includes('Lanche da Tarde') ||
          splitText[i].includes('Lanche da tarde') ||
          splitText[i].includes('Dieta Totalmente Personalizada e Exclusiva') ||
          splitText[i].includes('Janta')) {
        pdf.setTextColor(50, 205, 50); // Cor verde
      } else {
        pdf.setTextColor(0); // Volta à cor padrão do texto (preto)
      }

      pdf.text(margin, yPosition, splitText[i]);

      yPosition += fontSize - 2;
    }

    // Rodapé moderno
    pdf.setFillColor('#1C9E22'); // Cor de fundo verde
    pdf.rect(0, pageHeight - 10, pageWidth, 30, 'F');
    pdf.setFontSize(12); // Ajusta o tamanho da fonte
    pdf.setTextColor('#ffffff'); // Texto branco

    // Centraliza o texto no rodapé
    let footerText = 'Caso tenha alguma dúvida, entre em contato.';
    let textWidth = pdf.getTextWidth(footerText);
    pdf.text((pageWidth - textWidth) / 2, pageHeight - 5, footerText);

    const fileName = 'Nutri Inteligente.pdf';
    pdf.save(fileName);
  };


  function shouldApplyBold(line) {
    const palavrasChave = ['Opção' , 'opção', 'Café da Manhã' , 'Café da manhã', 'Lanche da Manhã' , 'Lanche da manhã', 'Almoço' , 'almoço', 'Lanche da Tarde' , 'Lanche da tarde', 'Janta' , 'Jantar', "Treino", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado", "Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira",];
    return palavrasChave.some(palavra => line.includes(palavra));
  }
  const dietaDividida = userDieta ? userDieta.split(/[\n]+/) : [];

const opcao1Index = dietaDividida.findIndex(line =>
  line.includes('Opção 1') || line.includes('opção 1')
);

const opcao2Index = dietaDividida.findIndex(line =>
  line.includes('Opção 2') || line.includes('opção 2')
);
const opcao3Index = dietaDividida.findIndex(line =>
  line.includes('Opção 3') || line.includes('opção 3')
);

  const cafeDaManhaIndex = dietaDividida.findIndex(line =>
    line.includes('Café da Manhã') || line.includes('Café da manhã') || line.includes('CAFÉ DA MANHÃ') || line.includes('Cafe da Manhã') || line.includes('Cafe da manhã')
  );

  const lancheDaManhaIndex = dietaDividida.findIndex(line =>
    line.includes('Lanche da Manhã') || line.includes('Lanche da manhã') || line.includes('LANCHE DA MANHÃ')
  );

  const almocoIndex = dietaDividida.findIndex(line =>
    line.includes('Almoço') || line.includes('almoço') || line.includes('ALMOÇO')
  );

  const lancheDaTardeIndex = dietaDividida.findIndex(line =>
    line.includes('Lanche da Tarde') || line.includes('Lanche da tarde') || line.includes('LANCHE DA TARDE')
  );

  const jantaIndex = dietaDividida.findIndex(line =>
    line.includes('Janta') || line.includes('Jantar') || line.includes('JANTAR') || line.includes('JANTA')
  );

  const treinoIndex = dietaDividida.findIndex(line =>
    line.includes('Treino') || line.includes('treino')
  );

  const inicioOpcao = dietaDividida.slice(0, opcao1Index)
  const opcao1 = dietaDividida.slice(opcao1Index, opcao2Index)
  const opcaa2 = dietaDividida.slice(opcao2Index, opcao3Index )
  const opcaa3 = dietaDividida.slice(opcao3Index, treinoIndex.length === -1 ? "": treinoIndex )
  const inicio = dietaDividida.slice(0, cafeDaManhaIndex)
  const cafeDaManha = dietaDividida.slice(cafeDaManhaIndex, lancheDaManhaIndex);
  const lancheDaManha = dietaDividida.slice(lancheDaManhaIndex, almocoIndex);
  const almoco = dietaDividida.slice(almocoIndex, lancheDaTardeIndex);
  const lancheDaTarde = dietaDividida.slice(lancheDaTardeIndex, jantaIndex);
  const janta = treinoIndex === -1 ? dietaDividida.slice(jantaIndex): dietaDividida.slice(jantaIndex, treinoIndex);
  const treino = dietaDividida.slice(treinoIndex ? treinoIndex : "");
  const [dietaEditavel, setDietaEditavel] = useState(dietaDividida);



    const handleChange = (event) => {
      const newValue = event.target.value.split('\n');
      setDietaEditavel(newValue);
  };
  return (
    <div className='flex flex-col items-center p-4 px-1 md:w-4/5 mx-auto'>

       {loading ? (
      <div className="flex justify-center items-center h-screen">
      <FontAwesomeIcon icon={faSpinner} color='#17A34A' spin size="3x" />
    </div>
    ) : (
      <div className='w-full'>

   <div className='  h-screen'>
        <header className='px-3'>
        </header>

        <div className='flex justify-between items-center mb-2'>
       {/* {atendimento && (
      <button
        className=' text-white mx-auto font-bold mb-4 p-3 py-3 rounded-[50px] bg-[#17A34A]'
        onClick={handleAcompanhamento}
      >
        Marcar Consulta 👩🏻‍⚕️
      </button>
    )} */}

    {atendimentoPersonalizado > 0 && (
      <button
        className=' text-white mx-auto font-bold mb-4 p-3 py-3 rounded-[50px] bg-[#17A34A]'
        onClick={handleAcompanhamentoPersonalizado}
      >
        Marcar consulta - Modificar dieta
      </button>
    )}

    </div>


      <div className='flex flex-column md:w-4/5 mx-auto'>
      {showError3 && !userDieta && possuiTicket && (
        <ErrorCard2
        message="Sua dieta ainda não foi montada. Clique em 'Montar Dieta' abaixo para preencher suas informações e gerar sua dieta não precisa pagar novamente"
        onClose={closeError3} // Fechar o card de erro quando o botão "OK" é clicado
        />
      )}

      {showErro2 && userDieta && (
        <ErrorCard3
          message="Acesse o site pelo navegador Safari ou Chrome para baixar o PDF da sua dieta"
          onClose={closeError2} // Fechar o card de erro quando o botão "OK" é clicado
        />
      )}

      {showError && !userDieta && !possuiTicket && info===true && (

        <ErrorCard
          id="error1" // Defina um ID único para este card
          message="Sua dieta esta sendo gerada, aguarde alguns minutos e recarregue sua pagina. Caso já tenha tenha recarregado a pagina, entre em contato."
          onClose={closeError} // Use a função closeError para fechar este card
        />
      )}

      {showError && !userDieta && !possuiTicket && info===false && (
        <ErrorCard2
          id="error1" // Defina um ID único para este card
          message="Você ainda não adquiriu sua dieta. Clique no botão abaixo, preencha as informações e compre sua dieta."
          onClose={closeError3} // Use a função closeError para fechar este card
        />
      )}


      </div>

      {userDieta && (<div>
        {edit === false ? (
        <div>
           <div className='w-[100%] lg:w-[60%] bg-white rounded-b-lg border-t-8 border-[#17A34A] px-2 py-4 flex flex-col justify-around shadow mx-auto my-3'>

           <h2
  className='text-2xl pb-2 mb-2 font-bold text-center'
  style={{ marginBottom: '-1.0rem' }} // Ajuste o valor negativo conforme necessário
>
  Veja sua dieta abaixo 👇🏼

</h2>

            {visibleDieta && (
          <div>

            {opcao1Index < cafeDaManhaIndex ? (<div>
          {inicioOpcao.map((line) => line).length !== 0 && <div className='bg-white-200 text-sm text-center py-2 mt-4 rounded-2xl'style={{ border: '1px solid #D9D8D8' }}>
          {inicioOpcao.map((line, index) => (
            <p className="p-1 m-0" key={index} style={{ color: 'black' }}>
        {line.replace(/[#@!$%^&*()_+=[\]{};':"\\|,.<>/?]+/g, '')}
      </p>
          ))}
          </div>}
          {opcao1.map((line) => line).length !== 0 && <div className='bg-white mt-5 py-2 px-2  rounded-2xl' style={{ border: '1px solid #D9D8D8' }}>
            <Banner icon={MenuI} titulo={'Opção de Refeição 1'} onClick={() => handleToggleElement(1)}></Banner>
            <div className={`${visibleIndices.includes(1) ? 'flex flex-col' : 'hidden' }  pb-4`}>
          {opcao1.map((line, index) =>{
            const cleanLine = line.replace(/\*/g, '').replace(/^#+\s*/, '');

            const caloriesText = cleanLine.includes('Calorias') || cleanLine.includes('calórico') || cleanLine.includes('calorias') || cleanLine.includes('calidades') || cleanLine.includes('Calidades')
            ? <strong>{cleanLine.replace(/Calorias|calórico/g, match => `${match} 🔥`)}</strong>
              : cleanLine;

            // Aplica formatação para "Opção 1", "Opção 2" e "Opção 3" com emoji e negrito
            const opcaoText = cleanLine.includes('Opção 1') || cleanLine.includes('Opção 2') || cleanLine.includes('Opção 3')
              ? <strong>{cleanLine.replace(/Opção 1|Opção 2|Opção 3/g, match => `${match} `)}</strong>
              : caloriesText;  // Mantém a formatação de calorias se não for uma opção

            return(
            <div className='p-1 m-0 mb-2 ' key={index} style={{ color: 'black' }}> {shouldApplyBold(line) ? (
              <>
      {line.includes('Opção') ? (<div className='m-0 p-0 '></div>) : (
        <Banner2 icon={horario} titulo={opcaoText}
        onClick={() => handleToggleElement(2)}></Banner2>
      )}
    </>
            )
             : (
              <p className='m-0 py-2 px-3'>
                {opcaoText}
              </p>

            )}</div>
          )})}
          </div>
          </div>}
          {opcaa2.map((line) => line).length !== 0 && <div className='bg-white mt-5 py-2 px-4 rounded-2xl 'style={{ border: '1px solid #D9D8D8' }}>
            <Banner icon={MenuI} titulo={'Opção de Refeição 2'} onClick={() => handleToggleElement(2)}></Banner>
            <div className={`${visibleIndices.includes(2) ? 'flex flex-col' : 'hidden' } pb-4 `}>
          {opcaa2.map((line, index) => { const cleanLine = line.replace(/\*/g, '').replace(/^#+\s*/, '');

const caloriesText = cleanLine.includes('Calorias') || cleanLine.includes('calórico') || cleanLine.includes('calorias') || cleanLine.includes('calidades') || cleanLine.includes('Calidades')
? <strong>{cleanLine.replace(/Calorias|calórico/g, match => `${match} 🔥`)}</strong>
  : cleanLine;

// Aplica formatação para "Opção 1", "Opção 2" e "Opção 3" com emoji e negrito
const opcaoText = cleanLine.includes('Opção 1') || cleanLine.includes('Opção 2') || cleanLine.includes('Opção 3')
  ? <strong>{cleanLine.replace(/Opção 1|Opção 2|Opção 3/g, match => `${match} `)}</strong>
  : caloriesText;  // Mantém a formatação de calorias se não for uma opção

return(
            <div className='p-1 m-0' key={index} style={{ color: 'black' }}> {shouldApplyBold(line) ? (
              <>
              {line.includes('Opção') ? (<div className='m-0 p-0 '></div>) : (
                <Banner2 icon={horario} titulo={opcaoText} onClick={() => handleToggleElement(2)}></Banner2>
              )}
            </>
              ) : (
                <p className='m-0 py-2 px-3'>{opcaoText}</p>              )}</div>
          )})}
          </div>
          </div>}
          {opcaa3.map((line) => line).length !== 0 && <div className='bg-white mt-5 py-2 px-4 rounded-2xl 'style={{ border: '1px solid #D9D8D8' }}>
            <Banner icon={MenuI} titulo={'Opção de Refeição 3'} onClick={() => handleToggleElement(3)}></Banner>
            <div className={`${visibleIndices.includes(3) ? 'flex flex-col' : 'hidden' } pb-4`}>
          {opcaa3.map((line, index) => { const cleanLine = line.replace(/\*/g, '').replace(/^#+\s*/, '');

const caloriesText = cleanLine.includes('Calorias') || cleanLine.includes('calórico') || cleanLine.includes('calorias') || cleanLine.includes('calidades') || cleanLine.includes('Calidades')
? <strong>{cleanLine.replace(/Calorias|calórico/g, match => `${match} 🔥`)}</strong>
  : cleanLine;

// Aplica formatação para "Opção 1", "Opção 2" e "Opção 3" com emoji e negrito
const opcaoText = cleanLine.includes('Opção 1') || cleanLine.includes('Opção 2') || cleanLine.includes('Opção 3')
  ? <strong>{cleanLine.replace(/Opção 1|Opção 2|Opção 3/g, match => `${match} `)}</strong>
  : caloriesText;  // Mantém a formatação de calorias se não for uma opção

return(

            <div className='p-1 m-0' key={index} style={{ color: 'black' }}> {shouldApplyBold(line) ? (
              <>
              {line.includes('Opção') ? (<div className='m-0 p-0 '></div>) : (
                <Banner2 icon={horario} titulo={opcaoText} onClick={() => handleToggleElement(2)}></Banner2>
              )}
            </>
              ) : (
            <p className=' m-0 py-2 px-3'>{opcaoText}</p>              )}</div>
          )})}
          </div>
          </div>}
          {treino.map((line) => line).length !== 1 && <div className='bg-white mt-5 mb-3 py-2 px-4 rounded-2xl 'style={{ border: '1px solid #D9D8D8'}} onClick={() => handleToggleElement(5)}>
            <Banner titulo={'Treino'} icon={treino1}></Banner>
            <div className={`${visibleIndices.includes(5) ? 'flex flex-col' : 'hidden' } `}>
          {treino.map((line, index) => {
           return(
            <p className='p-2 m-0' key={index} style={{ color: 'black' }}>
              {shouldApplyBold(line) ? (
                <strong className='my-2'>{line}</strong>
              ) : (
                line
              )}
            </p>
          )})}
          </div>
        </div>}
          </div>

        ) : ( <div>
   {inicio.map((line) => line.replace(/[#@!$%^&*()_+=[\]{};':"\\|,.<>/?]+/g, '')).length !== 0 && (
  <div className='bg-white text-center py-2 mt-4'>
    {inicio.map((line, index) => (
      <p className='p-1 m-0 text-1xl font-bold rounded-full font-poppins bg-green-100 text-green-600' key={index}
>
        {shouldApplyBold(line) ? (
          <strong className='my-2'>{line.replace(/[#@!$%^&*()_+=[\]{};':"\\|,.<>/?]+/g, '')}</strong>
        ) : (
          line.replace(/[#@!$%^&*()_+=[\]{};':"\\|,.<>/?]+/g, '')
        )}
      </p>
    ))}
  </div>
)}

<div
  className='bg-white mt-4 py-1 px-4 rounded-2xl shadow-xl'
  style={{ border: '1px solid #D9D8D8' }}
  onClick={() => handleToggleElement(0)} // Adiciona o evento de clique ao contêiner
>
  <Banner titulo={'Café da Manhã'} icon={coffe} />
  <div className={`${visibleIndices.includes(0) ? 'block' : 'hidden'} pb-4`}>
    {cafeDaManha.map((line, index) => {
      // Remove os asteriscos e outros caracteres indesejados
      const cleanLine = line.replace(/\*/g, '').replace(/^#+\s*/, '');

      // Aplica formatação para "Calorias" ou "calórico" com emoji e negrito
      const caloriesText = cleanLine.includes('Calorias') || cleanLine.includes('calórico') || cleanLine.includes('calorias') || cleanLine.includes('calidades') || cleanLine.includes('Calidades')
      ? <strong>{cleanLine.replace(/Calorias|calórico/g, match => `${match} 🔥`)}</strong>
        : cleanLine;

      // Aplica formatação para "Opção 1", "Opção 2" e "Opção 3" com emoji e negrito
      const opcaoText = cleanLine.includes('Opção 1') || cleanLine.includes('Opção 2') || cleanLine.includes('Opção 3')
        ? <strong>{cleanLine.replace(/Opção 1|Opção 2|Opção 3/g, match => `${match} `)}</strong>
        : caloriesText;  // Mantém a formatação de calorias se não for uma opção

      return (
        <p className='p-2 m-0' key={index} style={{ color: 'black' }}>
          {opcaoText}
        </p>
      );
    })}
  </div>
</div>





{lancheDaManha.map((line) => line.replace(/[#@!$%^&*()_+=[\]{};':"\\|,.<>/?]+/g, '')).length !== 0 && (
  <div
    className='bg-white mt-4 py-1 px-4 rounded-2xl shadow-xl'
    style={{ border: '1px solid #D9D8D8' }}
    onClick={() => handleToggleElement(1)} // Adiciona o evento de clique ao contêiner
  >
    <Banner titulo={'Lanche da Manhã'} icon={lancheM} />
    <div className={`${visibleIndices.includes(1) ? 'block' : 'hidden'} pb-4`}>
      {lancheDaManha.map((line, index) => {
        // Remove os asteriscos e outros caracteres indesejados
        const cleanLine = line.replace(/\*/g, '').replace(/^#+\s*/, '');

        // Aplica formatação para "Calorias" ou "calórico" com emoji e negrito
        const caloriesText = cleanLine.includes('Calorias') || cleanLine.includes('calórico') || cleanLine.includes('calorias') || cleanLine.includes('calidades') || cleanLine.includes('Calidades')
          ? <strong>{cleanLine.replace(/Calorias|calórico/g, match => `${match} 🔥`)}</strong>
          : cleanLine;

        // Aplica formatação para "Opção 1", "Opção 2" e "Opção 3" com emoji e negrito
        const opcaoText = cleanLine.includes('Opção 1') || cleanLine.includes('Opção 2') || cleanLine.includes('Opção 3')
          ? <strong>{cleanLine.replace(/Opção 1|Opção 2|Opção 3/g, match => `${match} `)}</strong>
          : caloriesText;  // Mantém a formatação de calorias se não for uma opção

        return (
          <p className='p-2 m-0' key={index} style={{ color: 'black' }}>
            {opcaoText}
          </p>
        );
      })}
    </div>
  </div>
)}



{almoco.map((line) => line.replace(/[#@!$%^&*()_+=[\]{};':"\\|,.<>/?]+/g, '')).length !== 0 && (
  <div
    className='bg-white mt-4 py-1 px-4 rounded-2xl shadow-xl'
    style={{ border: '1px solid #D9D8D8' }}
    onClick={() => handleToggleElement(2)} // Adiciona o evento de clique ao contêiner
  >
    <Banner titulo={'Almoço'} icon={almoco1} />
    <div className={`${visibleIndices.includes(2) ? 'block' : 'hidden'} pb-4`}>
      {almoco.map((line, index) => {
        // Remove os asteriscos e outros caracteres indesejados
        const cleanLine = line.replace(/\*/g, '').replace(/^#+\s*/, '');

        // Aplica formatação para "Calorias" ou "calórico" com emoji e negrito
        const caloriesText = cleanLine.includes('Calorias') || cleanLine.includes('calórico') || cleanLine.includes('calorias') || cleanLine.includes('calidades') || cleanLine.includes('Calidades')
          ? <strong>{cleanLine.replace(/Calorias|calórico/g, match => `${match} 🔥`)}</strong>
          : cleanLine;

        // Aplica formatação para "Opção 1", "Opção 2" e "Opção 3" com emoji e negrito
        const opcaoText = cleanLine.includes('Opção 1') || cleanLine.includes('Opção 2') || cleanLine.includes('Opção 3')
          ? <strong>{cleanLine.replace(/Opção 1|Opção 2|Opção 3/g, match => `${match} `)}</strong>
          : caloriesText;  // Mantém a formatação de calorias se não for uma opção

        return (
          <p className='p-2 m-0' key={index} style={{ color: 'black' }}>
            {opcaoText}
          </p>
        );
      })}
    </div>
  </div>
)}



{lancheDaTarde.map((line) => line.replace(/[#@!$%^&*()_+=[\]{};':"\\|,.<>/?]+/g, '')).length !== 0 && (
  <div
    className='bg-white mt-4 py-1 px-4 rounded-2xl shadow-xl'
    style={{ border: '1px solid #D9D8D8' }}
    onClick={() => handleToggleElement(3)} // Adiciona o evento de clique ao contêiner
  >
    <Banner titulo={'Lanche da Tarde'} icon={lanche} />
    <div className={`${visibleIndices.includes(3) ? 'block' : 'hidden'} pb-4`}>
      {lancheDaTarde.map((line, index) => {
        // Remove os asteriscos e outros caracteres indesejados
        const cleanLine = line.replace(/\*/g, '').replace(/^#+\s*/, '');

        // Aplica formatação para "Calorias" ou "calórico" com emoji e negrito
        const caloriesText = cleanLine.includes('Calorias') || cleanLine.includes('calórico') || cleanLine.includes('calorias') || cleanLine.includes('calidades') || cleanLine.includes('Calidades')
          ? <strong>{cleanLine.replace(/Calorias|calórico/g, match => `${match} 🔥`)}</strong>
          : cleanLine;

        // Aplica formatação para "Opção 1", "Opção 2" e "Opção 3" com emoji e negrito
        const opcaoText = cleanLine.includes('Opção 1') || cleanLine.includes('Opção 2') || cleanLine.includes('Opção 3')
          ? <strong>{cleanLine.replace(/Opção 1|Opção 2|Opção 3/g, match => `${match} `)}</strong>
          : caloriesText;  // Mantém a formatação de calorias se não for uma opção

        return (
          <p className='p-2 m-0' key={index} style={{ color: 'black' }}>
            {opcaoText}
          </p>
        );
      })}
    </div>
  </div>
)}



{janta.map((line) => line.replace(/[#@!$%^&*()_+=[\]{};':"\\|,.<>/?]+/g, '')).length !== 0 && (
  <div
    className='bg-white mt-4 py-2 px-4 rounded-2xl shadow-xl'
    style={{ border: '1px solid #D9D8D8' }}
    onClick={() => handleToggleElement(4)} // Adiciona o evento de clique ao contêiner
  >
    <Banner titulo={'Janta'} icon={janta1} />
    <div className={`${visibleIndices.includes(4) ? 'block' : 'hidden'} pb-4`}>
      {janta.map((line, index) => {
        // Remove os asteriscos e outros caracteres indesejados
        const cleanLine = line.replace(/\*/g, '').replace(/^#+\s*/, '');

        // Aplica formatação para "Calorias" ou "calórico" com emoji e negrito
        const caloriesText = cleanLine.includes('Calorias') || cleanLine.includes('calórico') || cleanLine.includes('calorias') || cleanLine.includes('calidades') || cleanLine.includes('Calidades')
          ? <strong>{cleanLine.replace(/Calorias|calórico/g, match => `${match} 🔥`)}</strong>
          : cleanLine;

        // Aplica formatação para "Opção 1", "Opção 2" e "Opção 3" com emoji e negrito
        const opcaoText = cleanLine.includes('Opção 1') || cleanLine.includes('Opção 2') || cleanLine.includes('Opção 3')
          ? <strong>{cleanLine.replace(/Opção 1|Opção 2|Opção 3/g, match => `${match} `)}</strong>
          : caloriesText;  // Mantém a formatação de calorias se não for uma opção

        return (
          <p className='p-2 m-0' key={index} style={{ color: 'black' }}>
            {opcaoText}
          </p>
        );
      })}
    </div>
  </div>
)}



{treino.map((line) => line).length !== 1 && <div className='bg-white mt-4 py-0 px-4 rounded-2xl shadow-xl' style={{ border: '1px solid #D9D8D8'}}  onClick={() => handleToggleElement(5)} >
  <Banner titulo={'Treino Personalizado'} icon={treino1}></Banner>
            <div className={`${visibleIndices.includes(5) ? 'block' : 'hidden' } pb-4 `}>
          {treino.map((line, index) => (
            <p className='p-2 m-0' key={index} style={{ color: 'black' }}>
              {shouldApplyBold(line) ? (
                <strong className='my-2'>{line}</strong>
              ) : (
                line
              )}
            </p>
          ))}
          </div>

        </div>
}
        </div>)}
       <br></br>
          </div>
         )}


             <div className="flex justify-between ">
    <div className="text-sm flex gap-2 mx-auto ">
            <button className='history  mt-[0.75rem]' onClick={() => handleDownloadPDF()}
          >
            Baixar
          </button>
           <button className='track' onClick={() => setVisibleDieta(!visibleDieta)}
           >
            {visibleDieta ? "Fechar" : "Ver" }
           </button>

           <button className='track' onClick={() => copyAnswer()}
             >
             Copiar
           </button>
           </div>

         </div>
         </div>

      </div> ) :
      (
        <div className='flex flex-col items-center'>
          <h1>Modo Edição de Dieta</h1>
          <textarea
        className='bg-white w-full text-black p-4 shadow-xl rounded-xl'
        style={{ lineHeight: '2.5' }}
            ref={resultTextareaRef}
                rows={dietaEditavel.length+5}
                value={dietaEditavel.join('\n')}
                onChange={handleChange}

            >
            </textarea>
        </div>
    )}





<br></br>
      {successMessageVisible && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    <div className="bg-white text-black py-4 px-6 rounded-lg shadow-xl border border-gray-300 text-center max-w-lg">
      <p className="text-xl font-semibold mb-2">Dieta copiada com sucesso 🎯</p>
      <p className="text-lg">Agora só colar 📄</p>
    </div>
  </div>
)}
      <HamburguerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu}></HamburguerMenu>

        {edit === true && (

          <div>
      <div className='flex items-center justify-around'>
      <button
        className="mt-4 hover:bg-red-600 text-white mx-auto px-6 py-3 rounded-lg bg-red-600 transition duration-300 ease-in-out"
        onClick={() => {
          handleSave()
          setEdit(!edit)
          retirarEdicaoDieta()
          window.location.reload()
         }}
      >
        Salvar
      </button>

<button
className="mt-4 hover:bg-red-600 text-white mx-auto px-6 py-3 rounded-lg bg-red-600 transition duration-300 ease-in-out"
onClick={() => {setEdit(!edit)
  setDietaEditavel(dietaDividida)}}
>
Cancelar
</button>
</div>

</div>
        )}
      </div>
      )}
    </div>
</div>
)}
</div>
  )}


