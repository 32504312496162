import { useNavigate } from 'react-router-dom';
import React from 'react';

import pessoa1 from '../../img/saladaverde.png';
function LoadInicial({onClose}) {
  
    const navigate = useNavigate()

        return (
            <div className="flex items-center justify-center min-h-screen p-4">
              <div className="text-center max-w-md w-full">
                <div className="flex justify-center mb-4">
                  <div className="w-24 h-24 rounded-full overflow-hidden">
                    <img src={pessoa1} alt="Salada Verde" className="w-full h-full object-cover" />
                  </div>
                </div>
                <h2 className="text-2xl font-bold">Deseja visualizar sua Dieta?</h2>
                <p className="text-gray-600 mt-2"> <strong>Você tem uma dieta salva</strong> <br></br>Quer visualizá-la ou gerar uma nova dieta?</p>
                <div className="mt-4 space-y-4 flex flex-col items-center">
                  <button className="bg-[#17A34A] text-white px-4 py-3 rounded-full w-50"onClick={() => navigate('/dieta')}>Ver minha Dieta</button>
                  <button className="border border-[#17A34A] text-[#17A34A] px-4 py-3 rounded-full w-60"onClick={onClose}>Montar Outra Dieta</button>
                </div>
              </div>
            </div>
          );
        }; 


export default LoadInicial;