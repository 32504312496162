
import React, { useState, useEffect } from 'react';
import '../../Styles/Desafio.css';
import SubscriptionComponent from '../../MercadoPago/SubscriptionComponent/Subscription.component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import mercadopago from '../../../img/mercado-pago-logo.png'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserAuth } from '../../../context/AuthContext';



function OrderBumpOverlay({ onConfirm, onCancel }) {
  return (
    <div className="overlay">
      <div className="order-bump-modal">
        <h2 className="text-xl font-bold text-center mb-2">Incluir Calorias na Dieta?</h2>
        <p className="text-center text-green-600 mb-2">Por apenas R$ 5,99 a mais!</p>
        <ul className="space-y-2 mb-4">
          <li className="flex items-center">
            <img className="w-10 h-10 mr-2 rounded-full bg-gray-100 p-2" src="https://openui.fly.dev/openui/24x24.svg?text=🔥" alt="flexed bicep" />
            <span className='text-black text-sm'>Calorias,Quantidades Calculo Imc</span>
          </li>
          <li className="flex items-center">
            <img className="w-10 h-10 mr-2 rounded-full bg-gray-100 p-2" src="https://openui.fly.dev/openui/24x24.svg?text=🥗" alt="salad bowl" />
            <span className='text-black text-sm'>Receitas fit, Lista de substituições</span>
          </li>
          <li className="flex items-center">
            <img className="w-10 h-10 mr-2 rounded-full bg-gray-100 p-2" src="https://openui.fly.dev/openui/24x24.svg?text=🛒" alt="shopping cart" />
            <span className='text-black text-sm'>Lista de compras dos alimentos</span>
          </li>
          <li className="flex items-center">
            <img className="w-10 h-10 mr-2 rounded-full bg-gray-100 p-2" src="https://openui.fly.dev/openui/24x24.svg?text=👍" alt="flexed bicep" />
            <span className='text-black text-sm'>Recomendação Whey e creatina</span>
          </li>
          <li className="flex items-center">
            <img className="w-10 h-10 mr-2 rounded-md bg-gray-100 p-2" src="https://openui.fly.dev/openui/24x24.svg?text=🔄" alt="pot of food" />
            <span className='text-black text-sm '> Modificação com a Nutricionista</span>
          </li>
        </ul>
        <div className="flex justify-around">
          <button onClick={onConfirm} className="bg-green-500 text-white font-bold py-2 px-4 rounded">Sim</button>
          <button onClick={onCancel} className="text-red-500 font-bold py-2 px-4 rounded">Não</button>
        </div>
      </div>
    </div>
  );
}



export default function DietaTreino() {
  const [addOrderBump, setAddOrderBump] = useState(false);
  const addOrderBumpT = true;
  const [amout, setAmount] = useState(11.99);
  const [showOrderBumpOverlay, setShowOrderBumpOverlay] = useState(true);
  const link = 'https://pay.kiwify.com.br/eVrMt3S'
  const {atendimentoNutricionista} = UserAuth()
  const navigate = useNavigate();

  useEffect(() => {
    // Mostra o overlay da order bump assim que o usuário acessa a página
    setShowOrderBumpOverlay(true);
  }, []);



  const handleChangeOrderBump = () => {
    const baseAmount = 11.99;
    const extraAmount = 5.99;

    setAddOrderBump(!addOrderBump);

    if (!addOrderBump) {
      setAmount(baseAmount + extraAmount);

    } else {
      setAmount(baseAmount);

    }
  };
  const handleConfirmOrderBump = () => {
    setAddOrderBump(true);
    setShowOrderBumpOverlay(false);
    handleChangeOrderBump(); // Atualiza o valor do pedido
  };

  const handleCancelOrderBump = () => {
    setShowOrderBumpOverlay(false);
  };

  const handlePaymentWaiting = () => {
    // Precisa ser melhor planejado/elaborado
    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa pagina!')

  };


  const handlePaymentSuccess = () => {
    if(addOrderBump === true){
      atendimentoNutricionista(true,true)
      }
    navigate('/comprarealizada');
  };

  return (
    <div className="payment-page">
      {showOrderBumpOverlay && (
        <OrderBumpOverlay
          onConfirm={handleConfirmOrderBump}
          onCancel={handleCancelOrderBump}
        />
      )}
       <div className='header-div'>
        <img src={mercadopago} alt='Logo Mercado Pago' />
      </div>

   {addOrderBump === true && (<div className="order-bump-container bg-gray-5 text-center  flex justify-center items-center">
  <div className= "bg-white shadow rounded-3xl px-3 py-2 mx-auto" style={{ maxWidth: '390px', padding: '10px' }}>
    <div className="order-bump-title mb-0.5 flex justify-center items-center">
      <h2 className="text-lg text-green-500  font-semibold inline-flex items-center">Direito a modificação <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 ml-2" /></h2>
    </div>
    <div className="p-0">
      <p className="text-gray-700 text-sm m-0">Consulta com a nutricionista gratuita<strong> incluída</strong>!</p>
    </div>
  
  </div>
</div>)
}


      <div className="payment-method-selector">
        <SubscriptionComponent amount={amout} onPaymentSuccess={handlePaymentSuccess} onPaymentWaiting={handlePaymentWaiting} addOrderBump={addOrderBumpT} linkexterno={link} />
      </div>


    </div>
  );
}