import React from 'react';
import pessoa2 from '../../img/macaverde.png'



const Suporte = () => {
  return (
    <div className="text-center p-6">
      <div className="flex justify-center mb-4">
        <div className="w-14 h-14 rounded-full  overflow-hidden">
          <img src={pessoa2} alt="" className="w-full h-full object-cover" />
        </div>
      
      </div>
      <h2 className="text-xl font-bold">Como receber a Dieta?</h2>
      <p className="text-muted-foreground mt-2">Após o pagamento, volte ao site e aguarde até 5 minutos para que sua dieta seja gerada. Você poderá visualizá-la ou baixá-la em PDF.</p>

<button
  className="bg-[#17A34A] text-white hover:bg-[#17A34A]/80 mt-2 px-4 py-2 rounded-full inline-block text-center no-underline"
> 
</button>
    </div>
  );
};

export default Suporte;

