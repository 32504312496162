import React from 'react';
import PropTypes from 'prop-types';
import Listapdf from '../PDF/Lista.pdf'
import ReceitasPdf from '../PDF/ReceitasPdf';
import { useNavigate } from 'react-router-dom';
import RecomendaWhey from '../PDF/RecomendaWhey/WheyRecomenda'
import RecomendaCreatina from '../PDF/RecomendaCreatina/CreatinaRecomenda';
import RecomendaFrutas from '../PDF/ListaFrutas/RecomendaFrutas'
function HamburgerMenuDieta({ isOpen, toggleMenu }) {
  
  const navigate = useNavigate()
  const Voltar = () => {
    navigate('/home')
  }

  const Compra = () => {
    navigate('/listacompras')
  }
    
  return (
    <div className=''>
    
          <nav className=''>
            <ul className=' my-3 flex flex-col justify-start lg:justify-start shadow-2xl rounded-lg items-center text-sm p-0 max-w-lg mx-auto'>

              {/* <img src={ImageMenu} className='w-[180px] h-[160px] lg:w-[120px] lg:h-[100px] p-0 m-0' alt="" /> */}

              <div className='flex flex-col justify-around w-full p-2'>
                   <h2 className='text-center font-bold'>Adicionais</h2>
                   <br></br>
                  <a className="flex p-5 border mb-3c my-2 text-sm md:text-base items-center text-black shadow-lg rounded-lg  w-full no-underline h-[50px] lg:h-[40px]" href={Listapdf} download="ListaSubstituição.pdf">
                  <h1 className='pr-2 m-0'>📋</h1> Lista de Substituição
                  </a>
                  <RecomendaWhey/>
                  <RecomendaCreatina/>
                  <RecomendaFrutas/>
                  <ReceitasPdf/>
                  <button className="flex border my-2 text-sm md:text-base items-center p-5 text-black shadow-lg rounded-lg w-full h-[50px] lg:h-[40px]  " onClick={Compra}>
                  <h1 className='pr-2 m-0'>🛒</h1> Lista de Compra
                  </button>
                  <button className="flex border my-2  text-sm md:text-base items-center p-5 text-black shadow-lg rounded-lg w-full h-[50px] lg:h-[40px]  " onClick={Voltar}>
                  <h1 className='pr-2 m-0'>🏠</h1> Página Inicial
                  </button>

                 
                 
        
              </div>
            </ul>
          </nav>
    
    </div>
  );

}

HamburgerMenuDieta.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default HamburgerMenuDieta;
