/* File: src/components/MercadoPago/SubscriptionComponent/Subscription.component.jsx */
import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Payment, StatusScreen } from '@mercadopago/sdk-react';
import api from '../../../services';
import PagamentoComponent from '../../Kiwify/PagamentoComponent';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../../context/AuthContext';
import { addPaymentId, getPaymentId, removePaymentId, setPaymentStartTime, getPaymentStartTime, removePaymentStartTime, getUserInfo } from '../../../helpers/localStorage.helper';
import formatCurrency from '../../../helpers/formatCurrency.helper';
import SubscriptionS from './Style';
import LoadingSpinner from '../LoadingSpinner.component';
import { removeUserInfo } from '../../../helpers/localStorage.helper';





function SubscriptionComponent({ amount, onPaymentSuccess, onPaymentWaiting, addOrderBump, linkexterno}) {
  
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentId, setPaymentId] = useState(getPaymentId() || null);


  const [isLoading, setIsLoading] = useState(true);
  const userInfo = getUserInfo()
 

  const { logout, user } = UserAuth();
  const navigate = useNavigate();
  



  /* Função responsável por verificar o status do pagamento do Pix */
  const getStatusPayment = useCallback(async (currentPaymentId) => {
    try {
      const response = await api.processPaymentStatus(user?.uid || userInfo?.userId, currentPaymentId, user?.accessToken || userInfo?.idToken);
      if (!response) {
        throw new Error('Erro ao verificar status do pagamento. Por favor, tente novamente.');
      }
      return response.status;
    } catch (error) {
      console.error('Error:', error);
      if (error.status === 401) {
        removeUserInfo();
        logout();
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        navigate('/');
      } else if (error.message === 'Payment not found') {
        setPaymentId(null);
        removePaymentId();
        removePaymentStartTime();
        toast.info('Pagamento não encontrado ou expirado. Inicie um novo pagamento.');
      } else {
        const errorMessage = error.message || 'Erro ao verificar status do pagamento. Por favor, tente novamente.';
        toast.error(errorMessage);
      }
      setIsLoading(false);
    }
  }, [user, navigate, logout, userInfo]);
  

  /* Função responsável por processar o status do pagamento */
  const handlePaymentStatus = useCallback(async () => {
    const paymentStartTime = getPaymentStartTime();
    const currentPaymentId = getPaymentId();
   
    if (!currentPaymentId) {
      console.error('Erro: ID do pagamento não encontrado.');
      return;
    }

      const timeElapsed = Date.now() - paymentStartTime;
      if (timeElapsed > 1200000) {
        // Se passaram 10 minutos, para as verificações
        await api.processPaymentStatus(user?.uid, currentPaymentId, user?.accessToken);
        setPaymentId(null);
        removePaymentId();
        removePaymentStartTime();
        // Notifica o usuário sobre o tempo expirado
        toast.error('Pagamento expirado. Inicie um novo pagamento.');
        return;
      }

      const status = await getStatusPayment(currentPaymentId);
      if (status === 'approved') {
        toast.success('Pagamento processado com sucesso!');
        setPaymentStatus('approved');
        onPaymentSuccess();
        removePaymentId();
      } else if (status === 'pending') {
        onPaymentWaiting();
      }
    

  
  }, [getStatusPayment, onPaymentSuccess, onPaymentWaiting, user]);

  const handleCancel = useCallback(async (showToast = true) => {
    
    try {
      await api.cancelPayment(paymentId, user?.accessToken);
      setPaymentId(null);
      removePaymentId();
      if (showToast) {
        toast.error('Pagamento cancelado com sucesso.');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);

      if (error.status === 401) {
        removeUserInfo();
        logout();
        if (showToast) {
          toast.error('Sessão expirada. Por favor, faça login novamente.');
        }
        navigate('/');
      } else if (error.message === 'Payment not found') {
        setPaymentId(null);
        removePaymentId();
        removePaymentStartTime();
        if (showToast) {
          toast.error('Pagamento não encontrado ou expirado. Inicie um novo pagamento.');
        }
      } else {
        const errorMessage = error.message || 'Erro ao processar o pagamento. Por favor, tente novamente.';
        if (showToast) {
          toast.error(errorMessage);
        }
      }
    }
  
  
}, [paymentId, user, navigate, logout]);


  /* useEffect para iniciar a verificação do status do pagamento e configurar o intervalo de verificação */
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
  
    const handleWindowFocus = () => {
      handlePaymentStatus()
    };

    const handleLoad = () => {
      setPageLoaded(true);
    };
  if(paymentId !== null){
    window.addEventListener('focus', handleWindowFocus);
  }

  let timer;
 const currentPaymentId = getPaymentId();
  if (currentPaymentId) {
  window.addEventListener('load', handleLoad);
  timer = setTimeout(() => {
      if (pageLoaded) {
        
      }
        handleWindowFocus();
    
    }, 1000); // 2000 milissegundos = 2 segundos
  }

  return () => {
    if (timer) {
      clearTimeout(timer); // Limpa o temporizador
    }
    window.removeEventListener('focus', handleWindowFocus);
    window.removeEventListener('load', handleLoad);
  };
}, [handlePaymentStatus, user?.accessToken,paymentId, pageLoaded]); 
  
  useEffect(() => {
    const handleCopyClick = (event) => {
      const isCopyButton = event.target.matches('#statusScreenBrick_container > div > section > div > section > button:nth-child(4), #statusScreenBrick_container > div > section > div > section > button:nth-child(4) span');

      if (isCopyButton) {
        toast.info("Código copiado com sucesso!");
      }
    };

    document.addEventListener('click', handleCopyClick);

    return () => {
      document.removeEventListener('click', handleCopyClick);
    };
  }, []);
  if (!user) {
    console.error("Usuário não autenticado ou informações não disponíveis.");
    logout();
    toast.error('Sessão expirada. Por favor, faça login novamente.');
    navigate('/');
    return null;
  }

  

  /* Função responsável por processar o pagamento via cartão de crédito */
  // const handleCreditCardPayment = async (formData, selectedPaymentMethod) => {
  //   try {
  //     const response = await api.processPayment({
  //       addOrderBump,
  //       userId: user?.uid,
  //       paymentDetails: formData,
  //       selectedPaymentMethod,
  //       description: process.env.REACT_APP_PAYMENT_DESCRIPTION,
  //       external_reference: process.env.REACT_APP_MERCADOPAGO_ID,
  //     }, user?.accessToken);

  //     if (response && response.status === 'approved') {
  //       setPaymentStatus('approved');
  //       setPaymentId(response.id);
  //       toast.success('Pagamento processado com sucesso!');
  //       onPaymentSuccess();
  //     } else {
  //       throw new Error(response.message || 'Erro desconhecido ao processar o pagamento.');
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setIsLoading(false);
      
  //     if (error.status === 401) {
  //       removeUserInfo();
  //       logout();
  //       toast.error('Sessão expirada. Por favor, faça login novamente.');
  //       navigate('/');
  //     } else {
  //       const errorMessage = error.message || 'Erro ao processar o pagamento. Por favor, tente novamente.';
  //       toast.error(errorMessage);
  //     }
  //   }
  // };

  /* Função responsável por processar o pagamento via Pix */
  const handlePixPayment = async (formData, selectedPaymentMethod) => {
  
    try {
      const response = await api.processPayment({
        addOrderBump,
        userId: user?.uid,
        paymentDetails: formData,
        selectedPaymentMethod,
        external_reference: process.env.REACT_APP_MERCADOPAGO_ID,
      }, user?.accessToken);
  
      if (response && response.status === 'pending') {
        addPaymentId(response.id);
        setPaymentId(response.id);
        setPaymentStartTime();
        handlePaymentStatus();
      } else {
        throw new Error(response.message || 'Erro ao processar o pagamento. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      
      if (error.status === 401) {
        removeUserInfo();
        logout();
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        navigate('/');
      } else {
        const errorMessage = error.message || 'Erro ao processar o pagamento. Por favor, tente novamente.';
        toast.error(errorMessage);
      }
    }
  };



  /* Função que é chamada quando o usuário clica em pagar */
  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    
      setIsLoading(true);

     
       if (selectedPaymentMethod === 'bank_transfer') {
        handlePixPayment(formData, selectedPaymentMethod);
      } else {
        toast.error('Método de pagamento inválido.');
      }
  

  };

  

  const onError = (error) => {
    console.log(error);
    toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.');
  };

  const onReady = async () => {
    setIsLoading(false);
  };

  return (
    <SubscriptionS>
      {isLoading && <LoadingSpinner />}
      <p className="amount">
        Valor do Pagamento :
        {' '}
        {formatCurrency(amount || 5.99)}
      </p>  
   
      {paymentId && paymentStatus !== 'approved' && (
      <div className=' hidden lg:block bg-[#f9f9f9] border shadow-md mx-auto my-2 p-3 rounded-xl w-[90%] md:w-[65%] '>
        <span className='text-red-500 font-semibold'>Após o Pagamento Clique no Botão Abaixo para Receber seu Ticket</span>
        <button type="button" className="primary " id="statusButton" onClick={() => handlePaymentStatus()}>Receber</button>
        </div>)}
      {!paymentId
        && ( <div>
        <Payment 
          initialization={{ amount: amount || 5.99 }}
          customization={{
            paymentMethods: {
              bankTransfer: 'all',
            },
          }}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
        />
        <p className='text-lg font-semibold text-[#1A1A1A]'>Cartão de Crédito 💳</p>
        <PagamentoComponent linkexterno={linkexterno}/>
        </div>
        )}
     
      {paymentId
        && (
        <StatusScreen
          initialization={{ paymentId }}
          customization={{
            visual: {
              showExternalReference: true,
            },
          }}
          onReady={onReady}
          onError={onError}
        />
        )}
          
      {paymentId && paymentStatus !== 'approved' && (
      <>
        <button type="button" className="secondary" id="cancelButton" onClick={() => handleCancel()}>Cancelar Pagamento</button>
      </>
      )}
      
    </SubscriptionS>
  );
}

export default SubscriptionComponent;
