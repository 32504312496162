import React from 'react';
import saladaverde from '../../../img/saladaverde.png';
import personalize from '../../../img/Personalize.png'
import fogo from '../../../img/fogo.png'

const Beneficios = () => {
  return (
    <div className="text-center p-6">
      <div className="flex justify-center mb-4">
        <div className="w-12 h-12 rounded-full border-2 border-white overflow-hidden">
          <img src={personalize} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="w-12 h-12 rounded-full border-2 border-white overflow-hidden">
          <img src={saladaverde} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="w-12 h-12 rounded-full border-2 border-white overflow-hidden">
          <img src={fogo} alt="" className="w-full h-full object-cover" />
        </div>
      </div>
      <h2 className="text-xl font-bold">PDF Personalizado com Sua Marca!</h2>
<p className="text-muted-foreground mt-2">Customize o PDF da dieta para refletir a identidade da sua marca.</p>
    </div>
  );
};

export default Beneficios;

