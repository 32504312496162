import React, { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import './FAQ.css';

const FAQ = () => {
  const faqData = [
    {
      question: 'A Nutri Inteligente tem CNPJ? ',
      answer: 'Sim, o CNPJ da Nutri Inteligente é 52.027.375/0001-49.'
    },
    {
      question: 'Quanto tempo tem a Nutri Inteligente?',
      answer: 'A Nutri Inteligente existe desde 2022 e tem uma trajetória de 2 anos no mercado.'
    },
    {
        question: 'Existe uma equipe de nutricionistas por trás deste site?',
        answer: 'Sim, claro! Contamos com uma equipe dedicada de nutricionistas altamente qualificados que estão prontos para fornecer orientação personalizada e apoio durante toda a sua jornada. Estamos comprometidos em garantir que você receba a melhor assistência nutricional para atingir suas metas de saúde.'
      },      
    {
        question: 'Como entrar em contato caso eu precise?',
        answer: 'Certamente! Para entrar em contato conosco, você pode utilizar diversas opções disponíveis no nosso site. Basta clicar no ícone do WhatsApp localizado na página, e ficaremos felizes em ajudar você com qualquer dúvida ou questão.'
      },

  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2 className="faq-heading">Perguntas Frequentes 🥦</h2>
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <div className={`question ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleAccordion(index)}>
            <span className="question-text">{item.question}</span>
            <FaAngleDown className={`arrow-icon ${activeIndex === index ? 'rotate' : ''}`} />
          </div>
          <div className={`answer ${activeIndex === index ? 'show' : ''}`}>
            {item.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
