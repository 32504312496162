
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Styles/Desafio.css';
import SubscriptionComponent from '../../MercadoPago/SubscriptionComponent/Subscription.component';
import { toast } from 'react-toastify';
import mercadopago from '../../../img/mercado-pago-logo.png'
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function InfoCard({ message, onClose }) {
  return (
    <div className="info-card-overlay">
      <div className="info-card">
        <p>{message}</p>
        <button onClick={onClose}>Eu Entendi!</button>
      </div>
    </div>
  );
}
function OrderBumpOverlay({ onConfirm, onCancel }) {
  return (
    <div className="overlay">
      <div className="order-bump-popup">
        <h2 className='TitleNutri'>Deseja uma Dieta mais Elaborada?</h2>
        <ul className="benefits-list">
          <li style={{ fontSize: '1.1em' }}><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Calorias,Quantidades e Calculo Imc. </li>
          <li style={{ fontSize: '1.1em' }}><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Receitas Fits com pratos Deliciosos.</li>
          <li style={{ fontSize: '1.1em' }}><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Treinos Personalizados e Exclusivo.</li>
        </ul>
        <p>
          Por um adicional de apenas <span className="price">R$ 5,99</span>, tenha uma Dieta mais elaborada.
        </p>
        <div className="buttons">
          <button onClick={onConfirm} className="confirm-button">Sim, Por Favor</button>
          <button onClick={onCancel} className="cancel-button">Não, Obrigado</button>
        </div>
      </div>
    </div>
  );
}


export default function Emagresce() {

  const [addOrderBump, setAddOrderBump] = useState(false);
  const [amout, setAmount] = useState(8.49);
  const [showCard, setShowCard] = useState(false);
  const [cardMessage, setCardMessage] = useState('');
  const [toMain, setToMain] = useState(false);
  const link = 'https://pay.kiwify.com.br/jqfUI3N'
  const navigate = useNavigate();
  const [showOrderBumpOverlay, setShowOrderBumpOverlay] = useState(true);

  const Voltar = () => {
    navigate('/home')
  }

  const handleChangeOrderBump = () => {
    const baseAmount = 8.49;
    const extraAmount = 5.99;

    setAddOrderBump(!addOrderBump);

    if (!addOrderBump) {
      setAmount(baseAmount + extraAmount);
    } else {
      setAmount(baseAmount);
    }
  };

  const handleCardClose = () => {
    setShowCard(false);
    if (toMain) navigate('/home');
  }

  const handlePaymentWaiting = () => {
    // Precisa ser melhor planejado/elaborado

    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa pagina!')

    setToMain(false);
  };

  const handlePaymentSuccess = () => {
    setCardMessage(
      <>
        <br />
        <strong>PAGAMENTO REALIZADO!</strong>Na página principal, preencha suas informações <strong>NOVAMENTE</strong>, como peso, altura, objetivo e preferências alimentares. 🍏🏋️‍♂️
        <br />
      </>
    );
    setToMain(true);
    setShowCard(true);
  };
  const handleConfirmOrderBump = () => {
    setAddOrderBump(true);
    setShowOrderBumpOverlay(false);
    handleChangeOrderBump(); // Atualiza o valor do pedido
  };

  const handleCancelOrderBump = () => {
    setShowOrderBumpOverlay(false);
  };

  return (
    <div className="payment-page">
       {showOrderBumpOverlay && (
        <OrderBumpOverlay
          onConfirm={handleConfirmOrderBump}
          onCancel={handleCancelOrderBump}
        />
      )}
      <div className='header-div'>
        <img src={mercadopago} alt='Logo Mercado Pago' />
      </div>
      <div className="aviso-container flex flex-col items-center mt-4">
      <FontAwesomeIcon icon={faInfoCircle} className="text-green-600 " />
        <p className="text-green-600 text-sm text-center">
        Após o pagamento, retorne à página e preencha seu peso, altura e alimentos novamente!
        </p>
      </div>
      <div className="payment-method-selector">
        <SubscriptionComponent amount={amout} onPaymentSuccess={handlePaymentSuccess} onPaymentWaiting={handlePaymentWaiting} addOrderBump={addOrderBump} linkexterno={link} />
      </div>

      <div className='flex justify-center items-center'>
        <button className='back-button' onClick={Voltar}>Voltar</button>
      </div>

      {showCard && <InfoCard message={cardMessage} onClose={handleCardClose} />}
    </div>
  );
} 