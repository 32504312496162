import React, { useState } from 'react';

const PatientRangeSelector = () => {
  const [patients, setPatients] = useState(1);
  const basePrice = 6.99;

  const calculatePrice = (numPatients) => {
    let price = basePrice;
    let multiplier = 1.26;

    for (let i = 2; i <= numPatients; i++) {
      if (i > 10) {
        price += 2;
      } else {
        price *= multiplier;
      }
    }
    
    return price.toFixed(2);
  };

  const handleRangeChange = (e) => {
    setPatients(Number(e.target.value));
  };

  const svgIcon = (
    <svg height="22" width="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#D1FAE5"></circle>
      <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
    </svg>
  );

  const features = [
    { icon: svgIcon, text: 'Treinamos você a mexer' },
    { icon: svgIcon, text: 'Baixe a dieta em PDF' },
    { icon: svgIcon, text: 'Economize Tempo' },
    { icon: svgIcon, text: 'Dieta Personalizada' },
    { icon: svgIcon, text: 'Treinos Personalizados' },
  ];
  

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Quantas dietas no mês?</h2>
      <br></br>
      <br></br>
      <div style={styles.rangeContainer}>
      <div style={{ ...styles.rangeLabel, left: `${(patients - 1) * 2}%`, textAlign: 'center' }}>
         {patients} Dietas
          <br />
          <span style={styles.priceText}>R$ {calculatePrice(patients)}</span>
        </div>
        <input
          type="range"
          min="1"
          max="50"
          value={patients}
          onChange={handleRangeChange}
          style={styles.rangeInput}
        />
      </div>
      <div style={styles.card}>
      <span className="bg-green-100 text-green-600 text-1-xs font-semibold px-2.5 py-0.5 rounded">{ 'Dietas no Mês:'} {patients}</span>
        <p style={styles.text}>Preço: R$ {calculatePrice(patients)}</p>
        <a
  href="https://wa.me/5524992178767"
  target="_blank"
  rel="noopener noreferrer"
  className="bg-green-200 font-semibold text-green-600 px-8 py-2 rounded-full mb-1 inline-block text-center no-underline"
>
  Começar agora
</a>

      </div>
      
      <div style={styles.benefitsCard}>
        <div className="text-center">
          <span className="bg-blue-100 text-blue-600 text-xs font-semibold px-2.5 py-0.5 rounded">Benefícios</span>
          <ul className="mt-6 space-y-3">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center">
                <span className="mr-2" style={styles.icon}>{feature.icon}</span>
                <span style={styles.benefitText}>{feature.text}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  rangeContainer: {
    position: 'relative',
    width: '80%',
    margin: '0 auto 20px',
  },
  rangeInput: {
    width: '100%',
    appearance: 'none',
    height: '15px',
    borderRadius: '8px',
    background: '#d3d3d3',
    outline: 'none',
    opacity: '0.7',
    transition: 'opacity .15s ease-in-out',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
  },
  rangeLabel: {
    position: 'absolute',
    top: '-45px',
    transform: 'translateX(-50%)',
    background: '#4CAF50',
    color: '#fff',
    padding: '2px 8px',
    borderRadius: '4px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  card: {
    maxWidth: '400px',
    margin: '20px auto',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    border: '1px solid #D9D8D8',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  text: {
    fontSize: '18px',
    color: '#333',
    margin: '10px 0',
  },
  benefitsCard: {
    maxWidth: '400px',
    margin: '20px auto',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    border: '1px solid #D9D8D8',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  icon: {
    color: '#17A34A',
    fontSize: '20px',
  },
  benefitText: {
    color: '#4d4d4d',
    fontSize: '0.9rem',
  },
};

// Additional styles for the range input thumb
const rangeThumbStyle = `
  input[type='range']::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4.5px; /* Adjust based on thumb size */
  }

  input[type='range']::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }

  input[type='range']::-ms-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
`;

// Inject the additional styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = rangeThumbStyle;
document.head.appendChild(styleSheet);

export default PatientRangeSelector;